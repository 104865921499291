<template>
  <vx-card actionable class="cardx" title="New Net Enquiries Report">
    <date-range-picker
      ref="picker"
      :opens="'right'"
      :locale-data="{
        firstDay: 1,
        format: 'DD-MM-YYYY',
        customRangeLabel: 'Custom Range',
      }"
      :minDate="null"
      :maxDate="null"
      :singleDatePicker="false"
      :timePicker="false"
      :timePicker24Hour="true"
      :showWeekNumbers="false"
      :showDropdowns="false"
      :autoApply="false"
      v-model="dateRange"
      style="width: 250px; margin-right:2%"
      @update="getNetEnquiries()"
    ></date-range-picker>

    <vs-row style="margin-top: 3%">
      <vs-table :data="tableData" style="width: 100%">
        <template slot="thead">
          <vs-th>City</vs-th>
          <vs-th>Total</vs-th>
          <vs-th>MHP</vs-th>
          <vs-th>Connected</vs-th>
          <!-- <vs-th>New Enquiries</vs-th> -->
          <!-- <vs-th>Old Enquiries</vs-th> -->
        </template>

        <template slot-scope="{ data }">
          <tbody ref="tbody">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <div v-if="tr.City">{{ tr.City }}</div>
                <div v-else>None</div>
              </vs-td>
              <vs-td>
                <div v-if="tr.Total">{{ tr.Total }}</div>
                <div v-else>0</div>
              </vs-td>
              <vs-td>
                <div v-if="tr.MHPs">{{ tr.MHPs }}</div>
                <div v-else>0</div>
                <!-- <div>{{ tr.MHPS }}</div> -->
              </vs-td>
              <vs-td>
                <div v-if="tr.Connected">{{ tr.Connected }}</div>
                <div v-else>0</div>
                <!-- <div>{{ tr.Connected }}</div> -->
              </vs-td>
              <!-- <vs-td>
                <div v-if="tr.New">{{ tr.New }}</div>
                <div v-else>0</div>
              </vs-td>
              <vs-td>
                <div v-if="tr.Old">{{ tr.Old }}</div>
                <div v-else>0</div>
              </vs-td> -->
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </vs-row>
  </vx-card>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import axios from "axios";
// import constants from "../../constants.json";
// import EventBus from "../components/eventbus";
// import moment from "moment";
export default {
  components: {
    DateRangePicker,
  },
  data() {
    return {
      dateRange: {
        startDate: this.getMonday(new Date()),
        endDate: new Date(),
      },
      tableData: [],
    };
  },
  mounted() {
    this.getNetEnquiries();
  },
  methods: {
    getNewEnquires(enquiries) {
      let returnArr = [];

      enquiries.forEach((enquiry) => {
        if (
          (enquiry.lead_added_on >
            this.datatoTimestamp(this.dateRange.startDate) &&
            enquiry.lead_added_on <
              this.datatoTimestamp(this.dateRange.endDate)) ||
          enquiry.lead_added_on === null
        ) {
          returnArr.push(enquiry);
        }
      });
      console.log(returnArr);
      return returnArr;
    },
    getOldEnquires(enquiries) {
      let returnArr = [];

      enquiries.forEach((enquiry) => {
        if (
          enquiry.lead_added_on !== null &&
          enquiry.lead_added_on < this.datatoTimestamp(this.dateRange.startDate)
        ) {
          returnArr.push(enquiry);
        }
      });
      console.log(returnArr);
      return returnArr;
    },
    getNetEnquiries() {
      this.$vs.loading();
      let obj = {
        startDate: this.datatoTimestamp(this.dateRange.startDate),
        endDate: this.datatoTimestamp(this.dateRange.endDate),
      };
      console.log(obj);
      // let url = `${constants.SERVER_API}getNetEnquiriesReport`;
      let url = `http://13.127.178.46/net-enquiry`;
      axios
        .get(url, {
          params: obj,
          // headers: {
          //   Authorization: `Bearer ${localStorage.userAccessToken}`,
          // },
        })
        .then((response) => {
          this.$vs.loading.close();
          console.log(response);
          let allcities = [];
          let otherData = [];
          response.data.forEach((city) => {
            if (
              city.City !== "International" &&
              city.City !== "Unmapped" &&
              city.City !== "Invalid"
            ) {
              allcities.push(city);
            } else {
              otherData.push(city);
            }
          });

          allcities = this.sortNestedArrayAlphabetically(allcities);

          this.tableData = allcities.concat(otherData);
          // this.createCityMatchedTable(response.data.data);
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },

    sortNestedArrayAlphabetically(ObjectsArray) {
      // console.log("going to sort the data");
      // console.log(ObjectsArray);
      let sortedArray = ObjectsArray.sort(function(a, b) {
        if (a.City < b.City) {
          return -1;
        }
        if (a.City > b.City) {
          return 1;
        }
        return 0;
      });
      // console.log(sortedArray);
      return sortedArray;
    },

    createCityMatchedTable(net_enquiries) {
      let matchedNetEnquiries = [];

      net_enquiries.forEach((netEnquiry) => {
        if (netEnquiry.found_city === 1) {
          matchedNetEnquiries.push(netEnquiry);
        }
      });

      let cities = this.extractCitiesFromNetEnquiries(matchedNetEnquiries);

      let tableData = [];

      cities.forEach((city) => {
        let obj = {
          city: city,
          enquiries: [],
          missed_hot_pocket: [],
          connected: [],
        };
        net_enquiries.forEach((netEnquiry) => {
          if (netEnquiry.suggested_city === city) {
            obj.enquiries.push(netEnquiry);
            obj[netEnquiry.status].push(netEnquiry);
          }
        });
        tableData.push(obj);
      });

      this.tableData = tableData;
    },
    extractCitiesFromNetEnquiries(response) {
      var unique = response
        .map((name) => {
          return {
            count: 1,
            name: name.suggested_city,
            details: name,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});

      let cities = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          cities.push(key);
        }
      }
      return cities;
    },
    getMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1);
      return new Date(d.setDate(diff));
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      if (isNaN(ms)) {
        let str = "";
        return str;
      }
      return ms;
    },
  },
};
</script>

<style></style>
